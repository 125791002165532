import * as echarts from 'echarts';
import { getStockLine } from '@/api/stock_api';

const prepareData = (rawData) => {
  const sortedData = rawData.sort((a, b) => parseInt(a.trade_date) - parseInt(b.trade_date));
  const dates = sortedData.map(item => item.trade_date);
  const data = sortedData.map(item => [item.open, item.close, item.low, item.high, item.pct]);
  const volumes = sortedData.map(item => item.vol);
  const ma5 = sortedData.map(item => item.ma5);
  const ma10 = sortedData.map(item => item.ma10);
  const ma20 = sortedData.map(item => item.ma20);
  const ma60 = sortedData.map(item => item.ma60);
  const ma120 = sortedData.map(item => item.ma120);
  const ma250 = sortedData.map(item => item.ma250);

  return { dates, data, volumes, ma5, ma10, ma20, ma60, ma120, ma250 };
};

const createChartOption = (dates, data, volumes, ma5, ma10, ma20, ma60, ma120, ma250) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: { type: 'cross' },
    position: (point, params, dom, rect, size) => {
      return [point[0] - size.contentSize[0], point[1] - size.contentSize[1]];
    },
    formatter: (params) => {
      const dataIndex = params[0].dataIndex;
      const date = dates[dataIndex];
      const closePrice = data[dataIndex][1];
      const pct = data[dataIndex][4];
      return `Date:${date}<br/>Close:${closePrice.toFixed(2)}<br/>Pct:${pct.toFixed(2)}`;
    },
  },
  grid: [
    { left: '5%', right: '1%', bottom: '19%', top: '1%' },
    { left: '5%', right: '1%', height: '15%', bottom: '0%' },
  ],
  xAxis: [
    { type: 'category', data: dates, gridIndex: 0, show: true },
    { type: 'category', data: dates, gridIndex: 1, axisLabel: { show: false } },
  ],
  yAxis: [
    { scale: true, gridIndex: 0 },
    { scale: true, gridIndex: 1, splitNumber: 2, axisLabel: { show: false } },
  ],
  dataZoom: [
        {
            type: 'inside',
            xAxisIndex: [0, 1],
            start: 0,
            end: 100,
            moveOnMouseMove: true,
            backgroundColor: 'rgba(0, 0, 0, 0)', // 完全透明背景
            borderColor: 'rgba(0, 0, 0, 0)', // 完全透明边框
            fillerColor: 'rgba(0, 0, 0, 0)', // 完全透明填充色
            handleColor: 'rgba(0, 0, 0, 0)' // 完全透明手柄颜色
        },
        {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '0%',
            height: 15,
            start: 0,
            end: 100,
            backgroundColor: 'rgba(0, 0, 0, 0)', // 完全透明背景
            borderColor: 'rgba(0, 0, 0, 0)', // 完全透明边框
            fillerColor: 'rgba(0, 0, 0, 0)', // 完全透明填充色
            handleColor: 'rgba(0, 0, 0, 0)' // 完全透明手柄颜色
        }
    ],
  series: [
    {
      type: 'candlestick',
      data: data,
      xAxisIndex: 0,
      yAxisIndex: 0,
      itemStyle: {
        color: '#ef232a',
        color0: '#14b143',
        borderColor: '#ef232a',
        borderColor0: '#14b143',
      },
    },
    {
      type: 'bar',
      data: volumes,
      xAxisIndex: 1,
      yAxisIndex: 1,
      itemStyle: {
        color: (params) => (data[params.dataIndex][1] > data[params.dataIndex][0] ? '#ef232a' : '#14b143'),
      },
    },
    { name: 'MA5', type: 'line', data: ma5, smooth: true, symbol: 'none', lineStyle: { color: '#00BFFF' }, xAxisIndex: 0, yAxisIndex: 0 },
    { name: 'MA10', type: 'line', data: ma10, smooth: true, symbol: 'none', lineStyle: { color: '#DC143C' }, xAxisIndex: 0, yAxisIndex: 0 },
    { name: 'MA20', type: 'line', data: ma20, smooth: true, symbol: 'none', lineStyle: { color: '#FFD700' }, xAxisIndex: 0, yAxisIndex: 0 },
    { name: 'MA60', type: 'line', data: ma60, smooth: true, symbol: 'none', lineStyle: { color: '#71d25b' }, xAxisIndex: 0, yAxisIndex: 0 },
    { name: 'MA120', type: 'line', data: ma120, smooth: true, symbol: 'none', lineStyle: { color: '#4682B4' }, xAxisIndex: 0, yAxisIndex: 0 },
    { name: 'MA250', type: 'line', data: ma250, smooth: true, symbol: 'none', lineStyle: { color: '#A0522D' }, xAxisIndex: 0, yAxisIndex: 0 },
  ],
});

export const weekKlineChart = async (elementId, { k_type, ts_code }) => {
  const chartDom = document.getElementById(elementId);
  if (!chartDom) {
    console.error('Chart DOM element not found:', elementId);
    return;
  }
  const myChart = echarts.init(chartDom);

  try {
    const response = await getStockLine({ data_type: k_type, ts_code });
    const rawData = response.data || response;
    const { dates, data, volumes, ma5, ma10, ma20, ma60, ma120, ma250 } = prepareData(rawData);
    const option = createChartOption(dates, data, volumes, ma5, ma10, ma20, ma60, ma120, ma250);
    myChart.setOption(option);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
