<!--
 * @Author: lianan27 lianan_27@163.com
 * @Date: 2024-03-09 19:52:31
 * @LastEditors: lianan27 lianan_27@163.com
 * @LastEditTime: 2024-03-12 00:04:29
 * @FilePath: \stockvue3\src\pages\RankingPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ranking_page">
    <!-- 下拉选择框 -->
    <div style="display: flex; align-items: center;">
    <div  style="margin-left: 20px">{{ deserializedParam.industry_name }}</div>
    <a-select :value="selectedMetric" @change="handleChange" style="width: 120px;margin: 10px 20px;">
      <a-select-option value="avg_price">平均价格</a-select-option>
      <a-select-option value="cap">市值</a-select-option>
      <a-select-option value="diff-pre">差值</a-select-option>
      <a-select-option value="shares">股份</a-select-option>
    </a-select>
  </div>
    <a-table 
      :columns="columns" 
      :dataSource="dataSource" 
      :scroll="{ x: 1500, y: 600 }" 
      :pagination="false"
      style="line-height: 0.5715; text-align: center;"
    ></a-table>
  </div>
</template>

<script>
import { Table, Select } from 'ant-design-vue';
import { getIndusFund } from "@/api/stock_api";

export default {
  components: {
    'a-table': Table,
    'a-select': Select,
    'a-select-option': Select.Option,
  },
  data() {
    return {
      selectedMetric: 'shares', // 默认显示shares
      columns: [],
      dataSource: [],
      rankingData: null, // 保存从API获取的原始数据
      deserializedParam: {}
    }
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    const param = params.get('param');
    if (param) {
      try {
        const deserializedParam = JSON.parse(decodeURIComponent(param));
        console.log('deserializedParam',deserializedParam);
        this.deserializedParam = deserializedParam
        this.fetchRankingData(deserializedParam);
        document.title = this.deserializedParam.industry_name;
      } catch (error) {
        console.error('Error parsing param:', error);
      }
    } else {
      console.error('No rankingData found in query');
    }
  },
  watch: {
    selectedMetric: {
    handler(newVal, oldVal) {
      console.log('selectedMetric changed from', oldVal, 'to', newVal);
      this.updateTable();
    },
    immediate: false,
    deep: false,
  }
},

  methods: {
    async fetchRankingData(param) {
      try {
        const data = await getIndusFund(param);
        this.processData(data);
        this.rankingData = data; // 保存获取的数据
      } catch (error) {
        console.error('Failed to fetch ranking data:', error);
      }
    },

    processData(rankingData) {
      const { report_periods, ...stocks } = rankingData;
      const stockNames = Object.keys(stocks);

      this.columns = [
        {
          title: '股票名称/时间',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left', // 固定首列
          width: 125, // 首列固定宽度
        },
        ...report_periods.map(period => ({
          title: period,
          dataIndex: period,
          key: period,
          sorter: (a, b) => Number(a[period]) - Number(b[period]),
          sortDirections: ['descend', 'ascend'], // 排序方向
        })),
      ];

      this.dataSource = stockNames.map(name => {
        const data = { name, key: name };
        report_periods.forEach(period => {
          // 使用selectedMetric决定展示哪个数据
          data[period] = stocks[name][period] ? stocks[name][period][this.selectedMetric] : '';
        });
        return data;
      });
    },
    handleChange(value) {
  this.selectedMetric = value;
  this.updateTable();
},

    updateTable() {
      console.log('updateTable called', this.selectedMetric);
      
       // 重新处理数据以更新表格显示
  if (this.rankingData) {
    this.processData(this.rankingData); // 使用保存的原始rankingData数据
  }
    },
  }
}
</script>

<style scoped>

</style>
