import * as echarts from 'echarts';
import { getKlineData } from '@/api/stock_api';

const prepareData = (rawData) => {
  const sortedData = rawData.sort((a, b) => parseInt(a.trade_date) - parseInt(b.trade_date));
  
  let dates = sortedData.map(item => item.trade_date);
  let data = sortedData.map(item => [item.open, item.close, item.low, item.high]);
  let volumes = sortedData.map(item => item.vol); // 添加成交量数据

  // // 反转数据，确保图表中数据的展示顺序是反向的
  // dates.reverse();
  // data.reverse();
  // volumes.reverse();

  return { dates, data, volumes };
};

const createChartOption = (dates, data, volumes) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    position: (point, params, dom, rect, size) => {
      return [point[0] - size.contentSize[0], point[1] - size.contentSize[1]];
    },
    formatter: (params) => {
      const dataIndex = params[0].dataIndex;
      const date = dates[dataIndex];
      const closePrice = data[dataIndex][1];
      return `日期：${date}<br/>收盘价：${closePrice.toFixed(2)}`;
    },
  },
  grid: [
    {
      left: '8%',
      right: '5%',
      bottom: '25%',
      top:'5%',
    },
    {
      left: '5%',
      right: '5%',
      top: '80%',
      bottom: '0',
    },
  ],
  xAxis: [
    {
      type: 'category',
      data: dates,
      gridIndex: 0,
    },
    {
      type: 'category',
      data: dates,
      gridIndex: 1,
      show: false,
    },
  ],
  yAxis: [
    {
      scale: true,
      gridIndex: 0,
    },
    {
      scale: true,
      gridIndex: 1,
      show: false,
    },
  ],
  dataZoom: [
    {
      type: 'inside',
      xAxisIndex: [0, 1],
      start: 0,
      end: 100,
      moveOnMouseMove: true,
    },
    {
      show: false,
      xAxisIndex: [0, 1],
      type: 'slider',
      bottom: '5%',
      height: 25,
      start: 0,
      end: 100,
    },
  ],
  toolbox: {
    show: false,
    feature: {
      dataZoom: {
        yAxisIndex: 'none'
      },
      restore: {},
      saveAsImage: {}
    }
  },
  series: [
    {
      type: 'candlestick',
      data: data,
      xAxisIndex: 0,
      yAxisIndex: 0,
      itemStyle: {
        color: '#ef232a',
        color0: '#14b143',
        borderColor: '#ef232a',
        borderColor0: '#14b143',
      },
    },
    {
      type: 'bar',
      data: volumes,
      xAxisIndex: 1,
      yAxisIndex: 1,
      itemStyle: {
        color: (params) => (data[params.dataIndex][1] > data[params.dataIndex][0] ? '#ef232a' : '#14b143'),
      },
    },
  ],
});

export const renderKlineChart = async (elementId, { k_type, ts_code }) => {
  const chartDom = document.getElementById(elementId);
  const myChart = echarts.init(chartDom);

  try {
    const response = await getKlineData({ k_type, ts_code });
    const rawData = response.data || response;
    const { dates, data, volumes } = prepareData(rawData);
    const option = createChartOption(dates, data, volumes);
    myChart.setOption(option);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
