<!-- @format -->

<template>
  <a-layout-header class="header">
    <div style="display: flex;justify-content: space-between;align-items: center;">
    <a-menu
      mode="horizontal"
      class="custom-menu"
      style="line-height: 50px; background-color: #0e5ed7"
    >
      <a-menu-item
        key="menu1"
        @click="$router.push('/ts_sw_stock')"
        style="margin-right: 50px; font-weight: bold; color: white"
        :class="{ 'ant-menu-item-selected': $route.path === '/ts_sw_stock' }"
      >
        <LineChartOutlined />
        行业排名
      </a-menu-item>
      <a-menu-item
        key="menu2"
        @click="$router.push('/HotAnalyze')"
        style="margin-right: 50px; font-weight: bold; color: white"
        :class="{ 'ant-menu-item-selected': $route.path === '/HotAnalyze' }"
      >
        <FireOutlined />
        行业个股
      </a-menu-item>
      <a-menu-item
        key="menu3"
        @click="$router.push('/StockRank')"
        style="margin-right: 50px; font-weight: bold; color: white"
        :class="{ 'ant-menu-item-selected': $route.path === '/StockRank' }"
      >
        <StarOutlined />
        突破年线
      </a-menu-item>
      <a-menu-item
        key="menu4"
        style="margin-right: 50px; font-weight: bold; color: white"
        @click="$router.push('/MyStock')"
        :class="{ 'ant-menu-item-selected': $route.path === '/MyStock' }"
      >
        <HeartOutlined />
        我的自选
      </a-menu-item>
      <a-menu-item
        key="menu5"
        style="font-weight: bold; color: white"
        @click="$router.push('/SerachStock')"
        :class="{ 'ant-menu-item-selected': $route.path === '/SerachStock' }"
      >
        <SearchOutlined />
        行业涨跌
      </a-menu-item>
    </a-menu>
    <a-button class="full-screen" @click="toggleFullScreen">全屏</a-button>
    </div>
  </a-layout-header>
</template>

<script>
import { createRouter, createWebHistory } from "vue-router";
import {
  LineChartOutlined,
  FireOutlined,
  StarOutlined,
  HeartOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "StockNavbar",
  components: {
    LineChartOutlined,
    FireOutlined,
    StarOutlined,
    HeartOutlined,
    SearchOutlined,
  },
  setup() {
    return {
      router: createRouter({
        history: createWebHistory(),
        routes: [
          {
            path: "/ts_sw_stock",
            name: "TsSwStock",
            component: () => import("@/pages/ts_sw_stock.vue"),
          },
        ],
      }),
      toggleFullScreen() {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
      },
    };
  },
  beforeCreate() {
    this.router.beforeEach((to, from, next) => {
      if (to.path === "/") {
        next("/ts_sw_stock");
      } else {
        next();
      }
    });
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  width: 100%;
  height: 50px;
  z-index: 1;
  background-color: #0e5ed7;
}
:deep(.ant-menu-item-selected::after) {
  border-bottom: 2px solid #ffffff !important;
}
:deep(.ant-menu-horizontal) {
  border-bottom: none;
}

:deep(
    .ant-menu-horizontal > .ant-menu-item::after,
    .ant-menu-horizontal > .ant-menu-submenu::after
  ) {
  bottom: 8px;
}

</style>
