<template>
  <div class="stock-table">
    <StockNavbar />
    <div class="container">
      <div class="radio-group">
        <span class="group-title">Indus Level : </span>
        <a-radio-group v-model="selectedLevel" @change="onLevelChange">
          <a-radio value="1">L1</a-radio>
          <a-radio value="2">L2</a-radio>
          <a-radio value="3">L3</a-radio>
        </a-radio-group>
      </div>
      <div class="radio-group">
        <span class="group-title">Indus Type : </span>
        <a-radio-group v-model="selectedType" @change="onTypeChange">
          <a-radio value="T">T</a-radio>
          <a-radio value="S">S</a-radio>
        </a-radio-group>
      </div>
      <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      row-key="industry_code"
      @change="handleTableChange"
      row-class-name="custom-row"
      :customRow="onTableRow"
      :scroll="{y: 'calc(90vh - 100px)'}"
      style="line-height: 0.5715;"
      >
      </a-table>
    </div>
    <a-modal 
      v-model:visible="isModalVisible" 
      :title="`${modalData.industry_name}${modalData.industry_code} `"
      width="100%"
      style="top: 0; max-width: 100%;"
    >
    <a-table
      :columns="detailColumns"
      :data-source="detailTableData"
      :pagination="false"
      style="height: 75vh;overflow: auto;line-height: 0.5715;"
      :scroll="{y: 'calc(80vh - 100px)'}"
      
    ></a-table>
  </a-modal>
  </div>
</template>

<script>
  import { Table } from "ant-design-vue";
  import StockNavbar from "@/components/StockNavbar.vue";
  import { getIndusRank } from "@/api";
  import { Radio } from 'ant-design-vue';
  import moment from 'moment';


  export default {
    name: "StockRank",
    components: {
      "a-table": Table,
      StockNavbar,
      'a-radio-group': Radio.Group,
      'a-radio': Radio,
    },
    data() {
      return {
        selectedLevel: '1', // 使用 selectedLevel 而非 selectedRadio
        selectedType: 'S', // 默认选中的值
        reloadData: false,
        tradeDate: '',
        columns: [
          { title: 'indus_name', dataIndex: 'industry_name', sorter: true, width: '120px'},
          { title: 'num', dataIndex: 'stock_num', sorter: true },
          { title: '>=20',
            dataIndex: 'uplimit20',
            sorter: true,
            customCell: (record) => {
            return {
              style: this.getCellStyle(record.uplimit20, record.stock_num, 'uplimit'),
            };
          },
          },
          { title: '10',
            dataIndex: 'uplimit10', 
            sorter: true,
            customCell: (record) => {
            return {
              style: this.getCellStyle(record.uplimit10, record.stock_num, 'uplimit'),
            };
            },
           },
          { title: '20_15', dataIndex: 'up15_20', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.up15_20, record.stock_num, 'up'),
            };
            }, },
          { title: '15_10', dataIndex: 'up10_15', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.up10_15, record.stock_num, 'up'),
            };
            }, 
        },
          { title: '10_5', dataIndex: 'up5_10', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.up5_10, record.stock_num, 'up'),
            };
            }, 
        },
          { title: '5_3', dataIndex: 'up3_5', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.up3_5, record.stock_num, 'up'),
            };
            }, 
        },
          { title: '3_0', dataIndex: 'up0_3', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.up0_3, record.stock_num, 'up'),
            };
            }, 
           },
          { title: '0', dataIndex: 'unchange', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.unchange, record.stock_num, 'unchange'),
            };
            }, 
        },
          { title: '0_3', dataIndex: 'down3_0', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.down3_0, record.stock_num, 'down'),
            };
            }, 
        },
          { title: '3_5', dataIndex: 'down5_3', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.down5_3, record.stock_num, 'down'),
            };
            }, 
        },
          { title: '5_10', dataIndex: 'down10_5', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.down10_5, record.stock_num, 'down'),
            };
            }, 
        },
          { title: '10_15', dataIndex: 'down15_10', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.down15_10, record.stock_num, 'down'),
            };
            }, 
        },
          { title: '15_20', dataIndex: 'down20_15', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.down20_15, record.stock_num, 'down'),
            };
            }, 
        },
          { title: '10', dataIndex: 'downlimit10', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.downlimit10, record.stock_num, 'downlimit'),
            };
            }, 
        },
          { title: '>=20', dataIndex: 'downlimit20', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.downlimit20, record.stock_num, 'downlimit'),
            };
            }, 
        },
        ],
        tableData: [],
        isModalVisible: false,
        modalData: {},
        detailColumns: [
          { title: 'trade_date', 
            dataIndex: 'trade_date', 
            sorter: true,
            width: '120px',
            render:(text) => {
              return this.formatDate(text);
            }
          },
          { title: 'num', dataIndex: 'stock_num', sorter: true },
          { title: '>=20', dataIndex: 'uplimit20', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.uplimit20, record.stock_num, 'uplimit'),
            };
            }, 
        },
          { title: '10', dataIndex: 'uplimit10', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.uplimit10, record.stock_num, 'uplimit'),
            };
            }, 
        },
          { title: '20_15', dataIndex: 'up15_20', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.up15_20, record.stock_num, 'up'),
            };
            }, 
        },
          { title: '15_10', dataIndex: 'up10_15', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.up10_15, record.stock_num, 'up'),
            };
            }, 
        },
          { title: '10_5', dataIndex: 'up5_10', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.up5_10, record.stock_num, 'up'),
            };
            }, 
        },
          { title: '5_3', dataIndex: 'up3_5', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.up3_5, record.stock_num, 'up'),
            };
            }, 
        },
          { title: '3_0', dataIndex: 'up0_3', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.up0_3, record.stock_num, 'up'),
            };
            }, 
        },
          { title: '0', dataIndex: 'unchange', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.unchange, record.stock_num, 'unchange'),
            };
            }, 
        },
          { title: '0_3', dataIndex: 'down3_0', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.down3_0, record.stock_num, 'down'),
            };
            }, 
        },
          { title: '3_5', dataIndex: 'down5_3', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.down5_3, record.stock_num, 'down'),
            };
            }, 
        },
          { title: '5_10', dataIndex: 'down10_5', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.down10_5, record.stock_num, 'down'),
            };
            }, 
        },
          { title: '10_15', dataIndex: 'down15_10', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.down15_10, record.stock_num, 'down'),
            };
            }, 
        },
          { title: '15_20', dataIndex: 'down20_15', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.down20_15, record.stock_num, 'down'),
            };
            }, 
        },
          { title: '10', dataIndex: 'downlimit10', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.downlimit10, record.stock_num, 'downlimit'),
            };
            }, 
        },
          { title: '>=20', dataIndex: 'downlimit20', sorter: true,
          customCell: (record) => {
            return {
              style: this.getCellStyle(record.downlimit20, record.stock_num, 'downlimit'),
            };
            }, 
        },
        ],
        detailTableData: [],
      };
    },
    methods: {
      onLevelChange(event) {
        this.selectedLevel = event.target.value; // 更新 selectedLevel
        this.fetchIndusRankData(this.selectedLevel, this.selectedType);
      },
      onTypeChange(event) {
        this.selectedType = event.target.value; // 更新 selectedType
        this.fetchIndusRankData(this.selectedLevel, this.selectedType);
      },
      getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },

      async fetchIndusRankData(level,type) {
        try {
          const response = await getIndusRank({
            level: level, // 使用传入的level参数
            type: type, // 使用传入的 type 参数
            trade_date: this.tradeDate // 使用传入的trade_date 参数 yyyy-mm-dd 格式
          });
          if (response && Array.isArray(response)) {
            this.tableData = response;
            // console.log('Data loaded', this.tableData);
          } else {
            console.error("Unexpected response structure", response);
          }
        } catch (error) {
          console.error("Error fetching the data:", error);
        }
      },
      handleTableChange(pagination, filters, sorter) {
        if (!sorter.field || !sorter.order) return;
        const order = sorter.order === 'ascend' ? 1 : -1;
        this.tableData.sort((a, b) => {
          if (a[sorter.field] < b[sorter.field]) {
            return -1 * order;
          }
          if (a[sorter.field] > b[sorter.field]) {
            return 1 * order;
          }
          return 0;
        });
      },
      async showModal(row) {
        this.modalData = row;
        this.isModalVisible = true;
        await this.fetchDetailData(row.industry_code);
    },
    async fetchDetailData(industryCode) {
      // console.log('industryCode',industryCode)
      try {
        const response = await getIndusRank({
          industry_code: industryCode,
          type: this.selectedType,
        });
        if (response && Array.isArray(response)) {
          this.detailTableData = this.formatTradeDates(response);
          // console.log('Detail Data loaded', this.detailTableData);
        } else {
          // console.error("Unexpected response structure", response);
        }
      } catch (error) {
        // console.error("Error fetching the detail data:", error);
      }
      },
      onTableRow(record) {
        return {
          onClick: () => {
            this.showModal(record);
          },
        };
      },
      formatTradeDates(data) {
        const formattedData = data.map(item => ({
          ...item,
          trade_date: moment(item.trade_date).format('YYYY-MM-DD'),
        }))
        return formattedData.sort((a, b) => moment(b.trade_date) - moment(a.trade_date));
      },
      getCellStyle(value, stockNum, type) {
        let color;
        let gradient;
        let percentage = (value / stockNum) * 100;

        // 根据类型设置颜色和宽度
        switch (type) {
          case 'uplimit':
            color = 'rgba(255, 0, 0, 1)'; // 全红
            gradient = `linear-gradient(to right, ${color} ${percentage}%, white ${percentage}%)`;
            break;
          case 'downlimit':
            color = 'rgba(0, 128, 0, 1)'; // 全绿
            gradient = `linear-gradient(to right, ${color} ${percentage}%, white ${percentage}%)`;
            break;
          case 'up':
            color = `rgba(255, 0, 0, ${value / stockNum})`; // 半透明红色
            gradient = `linear-gradient(to right, ${color} ${percentage}%, white ${percentage}%)`;
            break;
          case 'down':
            color = `rgba(0, 128, 0,  ${value / stockNum})`; // 半透明绿色
            gradient = `linear-gradient(to right, ${color} ${percentage}%, white ${percentage}%)`;
            break;
          default:
            color = 'rgba(128, 128, 128, 0.6)'; // 半透明灰色
            gradient = `linear-gradient(to right, ${color} ${percentage}%, white ${percentage}%)`;
        }

        return {
          background: gradient,
        };
  },
    },
    async mounted() {
      this.tradeDate = this.getCurrentDate();
      await this.fetchIndusRankData(this.selectedLevel, this.selectedType);
    },
  };
</script>

<style scoped>
  .selected-row {
    background-color: red !important;
  }
  .container {
    padding-top: 50px;
    position: relative;
    height: 100vh;
    /* overflow-y: auto; */
  }
  .fixed-checkbox {
    position: fixed;
    top: 15px;
    right: 10px;
    z-index: 999;
  }
  .white-text {
    color: white;
  }


  .stock-table .ant-table {
    overflow-x: auto;
    width: 100%;
  }
  .stock-table .ant-table-row,
  .stock-table .ant-table-row:hover {
    height: 5px;
    line-height: 0.5;
  }
  .stock-table .ant-table-row > td {
    padding: 4px 16px !important;
  }
  .ant-table .ant-table-tbody > tr > td.name-column {
    width: 400px !important;
    max-width: 400px !important;
    min-width: 400px !important;
  }

  .stock-table >>> .ant-modal-body {
    padding: 0 !important;
  }

  .stock-table >>> .ant-table-tbody > tr > td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
   
</style>
