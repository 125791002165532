<!-- @format -->

<template>
  <div class="stock-table">
    <StockNavbar />
    <div class="container">
      <!-- 添加固定位置的复选框 -->
      <div class="fixed-checkbox">
        <a-checkbox v-model="reloadData" @change="handleReloadChange">
          <span class="white-text">重新请求数据</span>
        </a-checkbox>
      </div>
      <a-table :columns="columns" :data-source="tableData" :pagination="false" row-key="ts_code" @change="handleTableChange"  @row-click="handleRowClick" :row-class-name="rowClassName" />
    </div>
  </div>
</template>

<script>
  import { Table } from "ant-design-vue";
  import StockNavbar from "@/components/StockNavbar.vue";
  import { getStockRank } from "@/api";

  export default {
    name: "StockRank",
    components: {
      "a-table": Table,
      StockNavbar,
    },
    data() {
      return {
        selectedRow: null,  // 添加此行来跟踪选中的行
        reloadData: false, // 初始化复选框状态为不勾选
        columns: [
          // { title: "Market", dataIndex: "market", sorter: true },
          // { title: "FreePct", dataIndex: "free_pct", sorter: true },
          // { title: "SW2_Name", dataIndex: "sw_name2", sorter: true },
          // { title: "SW3_Name", dataIndex: "sw_name3", sorter: true },
          // { title: "TSCode", dataIndex: "ts_code", sorter: true },
          { title: "TSName", dataIndex: "name", className: "name-column", sorter: true },
          { title: "RT_PCT", dataIndex: "realtime_pct", sorter: true },
          { title: "TL_3", dataIndex: "tl_3", sorter: true },
          { title: "TL_4", dataIndex: "tl_4", sorter: true },
          { title: "TL_5", dataIndex: "tl_5", sorter: true },
          { title: "TL_10", dataIndex: "tl_10", sorter: true },
          { title: "TL_15", dataIndex: "tl_15", sorter: true },
          { title: "TL_20", dataIndex: "tl_20", sorter: true },
          // { title: "TL_40", dataIndex: "tl_40", sorter: true },
          // { title: "TL_60", dataIndex: "tl_60", sorter: true },
          { title: "TL_120", dataIndex: "tl_120", sorter: true },
          { title: "TL_240", dataIndex: "tl_240", sorter: true },
          { title: "TH_3", dataIndex: "th_3", sorter: true },
          { title: "TH_5", dataIndex: "th_5", sorter: true },
          { title: "TH_10", dataIndex: "th_10", sorter: true },
         // { title: "TH_15", dataIndex: "th_15", sorter: true },
        // { title: "TH_20", dataIndex: "th_20", sorter: true },
         // { title: "TH_40", dataIndex: "th_40", sorter: true },
          // { title: "TH_60", dataIndex: "th_60", sorter: true },
          { title: "TH_120", dataIndex: "th_120", sorter: true },
          { title: "TH_240", dataIndex: "th_240", sorter: true },
          { title: "SW2_Name", dataIndex: "sw_name2", sorter: true },
          { title: "SW3_NameName", dataIndex: "sw_name3", sorter: true },
          { title: "TSCode", dataIndex: "ts_code", sorter: true },
          { title: "RT_Close", dataIndex: "realtime_close", sorter: true },
        ],
        tableData: [],
      };
    },
    async mounted() {
      try {
        const response = await getStockRank();
        // console.log("Full Response:", response);
        if (Array.isArray(response)) {
          this.tableData = response;
        } else if (response && response.data && Array.isArray(response.data)) {
          this.tableData = response.data;
        } else {
          console.error("Unexpected response structure", response);
        }
      } catch (error) {
        console.error("Error fetching the table data:", error);
      }
    },
    methods: {
      handleRowClick(record) {
        this.selectedRow = record.ts_code;  // 假设每行都有一个唯一的 'ts_code' 属性
      },
      rowClassName(record) {
        return record.ts_code === this.selectedRow ? 'selected-row' : '';
      },
      // 复选框状态变化时调用，更新 reloadData 的值
      handleReloadChange(checked) {
        this.reloadData = checked;
      },
      async handleTableChange(pagination, filters, sorter) {
        if (this.reloadData) {
          // 如果勾选重新请求，发起API请求
          const orderby = sorter.field;
          const order = sorter.order === "ascend" ? "asc" : "desc";
          try {
            const response = await getStockRank(undefined, undefined, undefined, orderby, order);
            if (Array.isArray(response)) {
              this.tableData = response;
            } else if (response && response.data && Array.isArray(response.data)) {
              this.tableData = response.data;
            } else {
              console.error("Unexpected response structure", response);
            }
          } catch (error) {
            console.error("Error fetching the table data:", error);
          }
        } else {
          // 如果不勾选重新请求，前端排序
          if (sorter.order) {
            this.tableData.sort((a, b) => {
              const valueA = a[sorter.field];
              const valueB = b[sorter.field];
              if (sorter.order === "ascend") {
                return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
              } else {
                return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
              }
            });
          }
        }
      },
    },

  };
</script>

<style scoped>
  .selected-row {
    background-color: red !important;  /* 或者使用 #ff0000 */
  }
  .container {
    padding-top: 50px;
    position: relative;
    overflow-y: auto; /* 启用垂直滚动 */
    height: 1000px; /* 或者你想要的任何高度 */
  }

  .fixed-checkbox {
    position: fixed;
    top: 15px;
    right: 10px;
    z-index: 999;
  }
  .white-text {
  color: white;
}
  .stock-table .ant-table {
    overflow-x: auto;
    width: 100%;
  }
  .ant-table-thead {
    position: sticky;
    top: 0;
    background-color: #fff; /* 如果您想要白色背景 */
    z-index: 1000; /* 为确保在其他内容上方 */
  }

  .ant-table-thead tr th {
    position: sticky;
    top: 0;
  }
  .stock-table .ant-table-row,
  .stock-table .ant-table-row:hover {
    height: auto;
    line-height: normal;
  }

  .stock-table .ant-table-row > td {
    padding: 4px 16px !important;
  }

  .ant-table .ant-table-tbody > tr > td.name-column {
    width: 400px !important;
    max-width: 400px !important;
    min-width: 400px !important;
  }

</style>
