<!-- @format -->

<!--
 * @Author: lianan27 lianan_27@163.com
 * @Date: 2023-03-27 21:59:05
 * @LastEditors: lianan27 lianan_27@163.com
 * @LastEditTime: 2024-03-17 22:42:47
 * @FilePath: \stock_vue3\src\pages\ts_sw_stock.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ts_sw_stock">
    <a-layout>
      <StockNavbar />
      <div class="container">
        <!-- 下拉选择框 -->
        <a-select :value="selectedMetric" @change="handleChange" style="width: 120px;margin: 10px 20px;">
          <a-select-option value="L1">L1</a-select-option>
          <a-select-option value="L2">L2</a-select-option>
          <a-select-option value="L3">L3</a-select-option>
        </a-select>
        <a-table 
          :columns="columns" 
          :dataSource="dataSource" 
          :pagination="false"
          style="line-height: 0.015; text-align: center;"
        ></a-table>
      </div>
    </a-layout>
  </div>
</template>

<script>
  import StockNavbar from "@/components/StockNavbar.vue";
  import { Table, Select } from 'ant-design-vue';
  import { getIndusRankDaily } from "@/api/stock_api";


  export default {
  components: {
    'a-table': Table,
    'a-select': Select,
    'a-select-option': Select.Option,
    StockNavbar
  },
  data() {
    return {
      selectedMetric: 'L2', // 默认选中的行业级别
      columns: [], // 表格列配置
      dataSource: [], // 表格数据源
      highlightedName: null, // 当前需要高亮的name
    };
  },
  mounted() {
    // 组件挂载后立即加载默认行业级别的数据
    this.fetchData();
  },
  methods: {
    async handleChange(value) {
      // 更新选中的行业级别并重新获取数据
      this.selectedMetric = value;
      this.fetchData();
    },
    // 组件的methods部分

async fetchData() {
  try {
    const response = await getIndusRankDaily(this.selectedMetric);
    if (response) {
      this.processData(response); // 调用processData处理具体逻辑
    }
  } catch (error) {
    console.error('Failed to fetch industry ranking data:', error);
  }
},

processData(data) {
  // 构建表格列
  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank_num',
      key: 'rank_num',
      fixed: 'left',
      width: 65,
      // align: 'center', // 确保文字居中
    }
  ];
  
  // 提取所有日期作为列标题，忽略rank_num
  const firstItem = data[0];
  let dates = Object.keys(firstItem).filter(key => key !== 'rank_num');
  // 对日期进行倒序排序
  dates.sort((a, b) => b.localeCompare(a));
  dates.forEach(date => {
    columns.push({
      title: date,
      dataIndex: date,
      key: date,
      width: 135,
      align: 'left', // 确保文字居中
      ellipsis: true, // 文本超出时显示...
      customCell: (record) => {
      const value = record[date]; // 假设这里直接获取到的是字符串 "pct-change-name"
      const regex = /^(-?\d+(\.\d+)?)-(.+)$/;
      const match = value.match(regex);

      if (!match) {
        console.error('无法解析的格式:', value);
        return { style: { color: 'blue' } }; // 出错时的样式
      }

      // const name = match[3]
      let pctChange = parseFloat(match[1]);
      // 调整 pctChange 以适应透明度的计算规则
      if (pctChange > 10) pctChange = 10;
      else if (pctChange < -10) pctChange = -10;

      let opacity = Math.abs(pctChange / 10); // 正确映射到0-1的透明度范围
      const color = pctChange > 0 ? `rgba(255, 0, 0, ${opacity})`
                    : pctChange < 0 ? `rgba(0, 128, 0, ${opacity})` : 'transparent';

      return {
        style: {
          backgroundColor: color,
        },
    //     class: this.highlightedName === name ? 'highlight' : '', // 动态绑定类名
    // on: {
    //   mouseenter: () => this.highlightedName = name, // 设置高亮的name
    //   mouseleave: () => this.highlightedName = null, // 清除高亮的name
    // },
      };
    },
    });
  });

  // 构建数据源
  const dataSource = data.map((item, index) => ({
    key: index,
    rank_num: item.rank_num,
    ...dates.reduce((acc, date) => {
      acc[date] = item[date].pct_change + '-' + item[date].name;
      return acc;
    }, {}),
  }));

  // 更新组件状态
  this.columns = columns;
  this.dataSource = dataSource;
}
  }
}

</script>

<style scoped>
.container {
  margin-top: 50px;
  /* 调整表格行高 */
}

.highlight {
  border: 2px solid #f00; /* 红色边框，根据需要自定义样式 */
}

</style>

