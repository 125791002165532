/*
 * @Author: lianan27 lianan_27@163.com
 * @Date: 2023-03-26 00:20:11
 * @LastEditors: lianan27 lianan_27@163.com
 * @LastEditTime: 2024-03-16 12:33:05
 * @FilePath: \stock_vue3\src\api\get_stock_data.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from './axiosConfig'

// 获取同花顺申万列表
export const getIndustry = () => {
    return axios.post('/api/industry_data')
}

// 获取同花顺申万3级行业股票列表
export const getIndusStock = (data) => {
    return axios.get('/api/get_stock_by_indus', {
        params: {
            indus_type: data.indus_type,
            indus_code: data.indus_code,
        }
    })
}

export const getKlineData = (data) => {
    return axios.post('/api/get_k_line', {
        k_type: data.k_type,
        ts_code: data.ts_code,
    })
}

export const getStockLine = (data) => {
    return axios.post('/api/get_stock_kline', {
        ts_code: data.ts_code,
        data_type: data.data_type,
    })
}

export const getStockBasic = (data) => {
    return axios.post('/api/get_stock_basic', {
        ts_code: data.ts_code,
    })
}

export const getStockBusiness = (data) => {
    return axios.post('/api/get_stock_business', {
        ts_code: data.ts_code,
    })
}

// 财务接口
export const getStockFinance = (data) => {
    return axios.post('/api/get_stock_finance', {
        ts_code: data.ts_code,
    })
}

// 股票持仓接口
export const getStockFund = (data) => {
    return axios.get(
        `/api/get_stock_fund?ts_code=${data}`
    )
}

// 股票持仓接口
export const getStockFundQy = ({ ts_code, report_period, org_type }) => {
    // 取 ts_code 参数的前 6 位
    const ts_code_short = ts_code.substring(0, 6);
    return axios.get(
        `/api/get_stock_fund_qy?ts_code=${ts_code_short}&report_period=${report_period}&org_type=${org_type}`
    );
};


// 行业持仓接口
export const getIndusFund = (data) => {
    return axios.get(
        `/api/get_indus_fund?indus_level=${data.indus_level}&indus_id=${data.industry_code.substring(0, 7)}`
    )
}

// 获取行业每日排名
export const getIdusRankDaily = (data) => {
    return axios.post('/api/get_indus_rank_daily', {
        indus_level: data.indus_level,
    })
}

// 同花顺概念接口
export const getStockConcept = (data) => {
    return axios.post('/api/get_stock_concept', {
        ts_code: data.ts_code,
    })
}

// 获取同花顺申万3级行业股票列表
export const getIndusStock1 = (data) => {
    return axios.get(
        `/api/get_stock_by_indus?indus_type=${data.indus_type}&indus_code=${data.indus_code}`
    )
}

// 行业代码
export const getIndusRankDaily = (data) => {
    return axios.get(
        `/api/get_indus_rank_daily?indus_level=${data}`
    )
}


export const getIndusRank = (params) => {
    const queryParams = new URLSearchParams();

    // 仅为非空值添加查询参数
    if (params.industry_code) queryParams.append('industry_code', params.industry_code);
    if (params.level) queryParams.append('level', params.level);
    if (params.type) queryParams.append('type', params.type);
    if (params.trade_date) queryParams.append('trade_date', params.trade_date);

    // 构建完整的 URL 并发送请求
    return axios.get(`/api/indus_rank?${queryParams.toString()}`);
}


// 获取当天全部股票累计涨跌幅接口
//export const getStockRank = () => {
//    return axios.get('/api/get_stock_rank')
//}

export const getStockRank = (indus_type, indus_code, indus_level,orderby, order) => {
    // 创建一个对象来保存查询参数
    const params = {};
    // 如果提供了 indus_type, indus_code 和 indus_level 参数，将它们添加到查询参数中
    if (indus_type) {
      params.indus_type = indus_type;
    }
    if (indus_code) {
      params.indus_code = indus_code;
    }
    if (indus_level) {
      params.indus_level = indus_level;
    }
     // 如果提供了 orderBy 和 order 参数，将它们添加到查询参数中
     if (orderby) {
        params.orderby = orderby;
      }
      if (order) {
        params.order = order;
      }
    // 将params对象传递给axios的get方法，以便将这些参数添加到查询字符串中
    return axios.get('/api/get_indus_stock_rank', { params });
};

// 获取突破年线的股票
export const getStockRankUpYearLine = (indus_type, indus_code, indus_level,orderby, order) => {
    // 创建一个对象来保存查询参数
    const params = {};
    // 如果提供了 indus_type, indus_code 和 indus_level 参数，将它们添加到查询参数中
    if (indus_type) {
      params.indus_type = indus_type;
    }
    if (indus_code) {
      params.indus_code = indus_code;
    }
    if (indus_level) {
      params.indus_level = indus_level;
    }
     // 如果提供了 orderBy 和 order 参数，将它们添加到查询参数中
     if (orderby) {
        params.orderby = orderby;
      }
      if (order) {
        params.order = order;
      }
    // 将params对象传递给axios的get方法，以便将这些参数添加到查询字符串中
    return axios.get('/api/get_indus_stock_rank_upyearline', { params });
};

// 其他用户相关的 API 接口
