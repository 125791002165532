import * as echarts from 'echarts';
import { getStockLine } from '@/api/stock_api';

const prepareData = (rawData) => {
  const sortedData = rawData.sort((a, b) => parseInt(a.trade_date) - parseInt(b.trade_date));
  const dates = sortedData.map(item => item.trade_date);
  const data = sortedData.map(item => [item.open, item.close, item.low, item.high, item.pct]);
  const volumes = sortedData.map(item => item.vol);
  const ma5 = sortedData.map(item => item.ma5);
  const ma10 = sortedData.map(item => item.ma10);
  const ma20 = sortedData.map(item => item.ma20);
  const ma60 = sortedData.map(item => item.ma60);
  const ma120 = sortedData.map(item => item.ma120);
  const ma250 = sortedData.map(item => item.ma250);
  const macd = sortedData.map(item => item.macd);
  const macd_dea = sortedData.map(item => item.macd_dea);
  const macd_dif = sortedData.map(item => item.macd_dif);

  return { dates, data, volumes, ma5, ma10, ma20, ma60, ma120, ma250, macd, macd_dea, macd_dif };
};

const createChartOption = (dates, data, volumes, ma5, ma10, ma20, ma60, ma120, ma250, macd, macd_dea, macd_dif) => ({
  tooltip: {
      trigger: 'axis',
      axisPointer: {
          type: 'cross'
      },
      position: (point, params, dom, rect, size) => {
        // 计算 tooltip 的目标位置
        let x = point[0] - size.contentSize[0] - 80;
        let y = point[1] - size.contentSize[1] + 80;
      
        // 检查 tooltip 是否会超出图表的左边缘
        if (x < 0) {
          x = point[0];
        }      
        // 检查 tooltip 是否会超出图表的上边缘
        if (y < 0) {
          y = point[1];
        }      
        return [x, y];
      },
      formatter: (params) => {
        const dataIndex = params[0].dataIndex;
        const date = dates[dataIndex];
        const closePrice = data[dataIndex][1];
        const pct = data[dataIndex][4];
        const volume = volumes[dataIndex];
        const ma5Value = ma5[dataIndex];
        const ma10Value = ma10[dataIndex];
        const ma60Value = ma60[dataIndex];
        const ma250Value = ma250[dataIndex];
        // 检查值是否为 null 或 undefined，如果是就返回一个默认值
        const formatValue = (value) => value === null || value === undefined ? 'N/A' : value.toFixed(2);     
        return `Date:${date}<br/>Close:${formatValue(closePrice)}<br/>Pct:${formatValue(pct)}<br/>Volume:${volume}<br/>MA5:${formatValue(ma5Value)}<br/>MA10:${formatValue(ma10Value)}<br/>MA60:${formatValue(ma60Value)}<br/>MA250:${formatValue(ma250Value)}`;
      },
  },
  grid: [
    { left: '5%', right: '1%', bottom: '36%', top: '3%' }, // K线图的网格配置
    { left: '5%', right: '1%', height: '10%', bottom: '20%' }, // 成交量图的网格配置
    { left: '5%', right: '1%', height: '20%', bottom: '0%' }, // MACD图的网格配置
  ],
  xAxis: [
    // K线图和成交量图的X轴设置不变
    { type: 'category', data: dates, gridIndex: 0}, // 隐藏K线图的X轴标签
    { type: 'category', data: dates, gridIndex: 1, axisLabel: { show: false } }, // 隐藏成交量图的X轴标签
    { type: 'category', data: dates, gridIndex: 2, show: false }, // 隐藏MACD图的X轴
  ],
  yAxis: [
    // K线图的Y轴设置不变
    { scale: true, gridIndex: 0}, 
    // 成交量图的Y轴设置，添加隐藏Y轴标签
    { scale: true, gridIndex: 1, splitNumber: 2, axisLabel: { show: false } }, // 隐藏成交量图的Y轴标签
    // MACD图的Y轴设置，确保它是隐藏的
    { scale: true, gridIndex: 2, splitNumber: 3, show: false }, // 隐藏MACD图的Y轴
  ],
  dataZoom: [
        {
            type: 'inside',
            xAxisIndex: [0, 1, 2],
            start: 0,
            end: 100,
            moveOnMouseMove: true,
            backgroundColor: 'rgba(0, 0, 0, 0)', // 完全透明背景
            borderColor: 'rgba(0, 0, 0, 0)', // 完全透明边框
            fillerColor: 'rgba(0, 0, 0, 0)', // 完全透明填充色
            handleColor: 'rgba(0, 0, 0, 0)' // 完全透明手柄颜色
        },
        {
            show: true,
            xAxisIndex: [0, 1, 2],
            type: 'slider',
            top: '1%',
            height: 15,
            start: 0,
            end: 100,
            backgroundColor: 'rgba(0, 0, 0, 0)', // 完全透明背景
            borderColor: 'rgba(0, 0, 0, 0)', // 完全透明边框
            fillerColor: 'rgba(0, 0, 0, 0)', // 完全透明填充色
            handleColor: 'rgba(0, 0, 0, 0)' // 完全透明手柄颜色
        }
    ],
  series: [
      {
          type: 'candlestick',
          data: data,
          xAxisIndex: 0,
          yAxisIndex: 0,
          itemStyle: {
              color: '#ef232a',
              color0: '#14b143',
              borderColor: '#ef232a',
              borderColor0: '#14b143',
          },
          markPoint: {
            symbolSize: 35,
            data: dates.reduce((acc, date, index, array) => {
                const month = date.slice(4, 6);
                const nextMonth = dates[index + 1] ? dates[index + 1].slice(4, 6) : null;
        
                 // 跳过最后一个月份的数据点
                if (index !== array.length - 1 && ['03', '06', '09', '12'].includes(month) && nextMonth !== month) {
                    const quarter = month === '03' ? 'Q1' : month === '06' ? 'Q2' : month === '09' ? 'Q3' : 'Q4';
                    acc.push({
                        name: quarter,
                        coord: [index, data[index][1]],
                        label: {
                            formatter: '{b}'
                        }
                    });
                }
        
                return acc;
            }, [])
          }
      },
      {
          type: 'bar',
          data: volumes,
          xAxisIndex: 1,
          yAxisIndex: 1,
          itemStyle: {
              color: (params) => (data[params.dataIndex][1] > data[params.dataIndex][0] ? '#ef232a' : '#14b143'),
          },
      },
      { name: 'MA5', type: 'line', data: ma5, smooth: true, symbol: 'none', lineStyle: { color: '#00BFFF' }, xAxisIndex: 0, yAxisIndex: 0 },
      { name: 'MA10', type: 'line', data: ma10, smooth: true, symbol: 'none', lineStyle: { color: '#DC143C' }, xAxisIndex: 0, yAxisIndex: 0 },
      { name: 'MA20', type: 'line', data: ma20, smooth: true, symbol: 'none', lineStyle: { color: '#FFD700' }, xAxisIndex: 0, yAxisIndex: 0 },
      { name: 'MA60', type: 'line', data: ma60, smooth: true, symbol: 'none', lineStyle: { color: '#71d25b' }, xAxisIndex: 0, yAxisIndex: 0 },
      { name: 'MA120', type: 'line', data: ma120, smooth: true, symbol: 'none', lineStyle: { color: '#4682B4' }, xAxisIndex: 0, yAxisIndex: 0 },
      { name: 'MA250', type: 'line', data: ma250, smooth: true, symbol: 'none', lineStyle: { color: '#A0522D' }, xAxisIndex: 0, yAxisIndex: 0 },
      {
          name: 'MACD',
          type: 'bar',
          data: macd,
          xAxisIndex: 2,
          yAxisIndex: 2,
          itemStyle: {
              color: (params) => params.value >= 0 ? '#ef232a' : '#14b143'
          }
      },
      {
        name: 'MACD Signal',
        type: 'line',
        data: macd_dea,
        smooth: true,
        symbol: 'none',
        lineStyle: { width: 2, color: '#71d25b'  }, // Updated to a red color for better contrast
        xAxisIndex: 2,
        yAxisIndex: 2
      },
      {
        name: 'MACD Line',
        type: 'line',
        data: macd_dif,
        smooth: true,
        symbol: 'none',
        lineStyle: { width: 2, color: '#ff4500' }, // Blue color as previously set
        xAxisIndex: 2,
        yAxisIndex: 2
      }      
  ],
});

export const dailyKlineChart = async (elementId, { k_type, ts_code }) => {
  const chartDom = document.getElementById(elementId);
  if (!chartDom) {
      console.error('Chart DOM element not found:', elementId);
      return;
  }
  const myChart = echarts.init(chartDom);

  try {
      const response = await getStockLine({ data_type: k_type, ts_code });
      const rawData = response.data || response;
      const { dates, data, volumes, ma5, ma10, ma20, ma60, ma120, ma250, macd, macd_dea, macd_dif } = prepareData(rawData);
      const option = createChartOption(dates, data, volumes, ma5, ma10, ma20, ma60, ma120, ma250, macd, macd_dea, macd_dif);
      myChart.setOption(option);
  } catch ( error) {
      console.error('Error fetching data:', error);
  }
};
