import * as echarts from 'echarts'

export function renderBarLabel(chartDom, list) {
    var app = {}
    var myChart = echarts.init(chartDom)
    var option

    const posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight',
    ]
    app.configParameters = {
        rotate: {
            min: -90,
            max: 90,
        },
        align: {
            options: {
                left: 'left',
                center: 'center',
                right: 'right',
            },
        },
        verticalAlign: {
            options: {
                top: 'top',
                middle: 'middle',
                bottom: 'bottom',
            },
        },
        position: {
            options: posList.reduce(function (map, pos) {
                map[pos] = pos
                return map
            }, {}),
        },
        distance: {
            min: 0,
            max: 100,
        },
    }
    app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
            const labelOption = {
                rotate: app.config.rotate,
                align: app.config.align,
                verticalAlign: app.config.verticalAlign,
                position: app.config.position,
                distance: app.config.distance,
            }
            myChart.setOption({
                series: [
                    {
                        label: labelOption,
                    },
                    {
                        label: labelOption,
                    },
                    {
                        label: labelOption,
                    },
                    {
                        label: labelOption,
                    },
                ],
            })
        },
    }
    const labelOption = {
        show: false,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
            name: {},
        },
    }
    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
          grid: [
    {
      left: '8%',
      right: '5%',
      bottom: '15%',
      top:'5%',
    },
    {
      left: '5%',
      right: '5%',
      top: '80%',
      bottom: '0',
    },
  ],
        xAxis: [
            {
                type: 'category',
                axisTick: { show: false },
                data: [],
            },
        ],
        yAxis: [
            {
                type: 'value',
            },
        ],
        series: [
            {
                name: 'total_revenue',
                type: 'bar',
                barGap: 0,
                label: labelOption,
                emphasis: {
                    focus: 'series',
                },
                data: [], // 动态更新
            },
            {
                name: 'n_income_attr_p',
                type: 'bar',
                label: labelOption,
                emphasis: {
                    focus: 'series',
                },
                data: [], // 动态更新
            },
        ],
    }

    // 获取 unique_end_dates 数组
    const uniqueEndDates = list[list.length - 1].unique_end_dates || []

    // Set xAxis data
    option.xAxis[0].data = uniqueEndDates
    // 设置 series 的 data
    option.series[0].data = list.map((item) => item.total_revenue)
    option.series[1].data = list.map((item) => item.n_income_attr_p)

    option && myChart.setOption(option)
}
