/*
 * @Author: lianan27 lianan_27@163.com
 * @Date: 2023-10-30 21:19:48
 * @LastEditors: lianan27 lianan_27@163.com
 * @LastEditTime: 2023-10-30 22:15:22
 * @FilePath: \stockvue3\src\api\axiosConfig.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';

const instance = axios.create({
 // baseURL: 'http://106.53.138.70:5000/', // 设置 API 基本 URL
  baseURL: 'http://42.193.236.164:5001/', // 设置 API 基本 URL
  // baseURL: 'http://stock.gbsaas.com/', // 设置 API 基本 URL

  timeout: 300000, // 设置请求超时时间
  headers: {
    'Content-Type': 'application/json', // 设置默认请求头
  },
});

// 添加请求拦截器
instance.interceptors.request.use((config) => {
  // 可以在此处添加一些全局的请求配置，如认证信息
  return config;
}, (error) => {
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use((response) => {
  // 对响应数据进行处理
  return response.data;
}, (error) => {
  // 对响应错误进行处理
  return Promise.reject(error);
});

export default instance;
