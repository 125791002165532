<!-- @format -->

<!--
 * @Author: lianan27 lianan_27@163.com
 * @Date: 2023-03-27 21:59:05
 * @LastEditors: lianan27 lianan_27@163.com
 * @LastEditTime: 2024-03-10 16:25:44
 * @FilePath: \stock_vue3\src\pages\ts_sw_stock.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ts_sw_stock">
    <a-layout>
      <StockNavbar />
      <a-layout>
        <a-layout-sider
          width="260"
          collapsible
          :style="{
            overflow: 'auto',
            height: '93%',
            position: 'fixed',
            left: 0,
          }"
          @collapse="handleSidebarCollapse"
        >
          <a-menu v-if="industriesLoaded" ref="menu" mode="inline" theme="light">
            <a-sub-menu key="ths">
              <template v-slot:title>
                <span>同花顺行业</span>
              </template>
              <a-sub-menu v-for="item in getIndustriesByTypeLevel('T', 1)" :key="item.industry_code" :title="`${item.industry_name}-${item.ts_code_count} | ${item.up_over10} ${item.up10_5} ${item.up5_0}`" @click="fetchSubIndustries(item)" @titleClick="handleTitleClick({ key: item.industry_code }, item)">
                <template v-if="subIndustries[item.industry_code]">
                  <a-sub-menu v-for="subItem in subIndustries[item.industry_code]" :key="subItem.industry_code" :title="`${subItem.industry_name}-${subItem.ts_code_count} | ${subItem.up_over10} ${subItem.up10_5} ${subItem.up5_0}`" @click="fetchSubIndustries(subItem)" @titleClick="handleTitleClick({ key: subItem.industry_code }, subItem)">
                    <template v-if="subIndustries[subItem.industry_code]">
                      <a-menu-item v-for="thirdLevelItem in subIndustries[subItem.industry_code]" :key="thirdLevelItem.industry_code" @click="selectIndustry(thirdLevelItem)">
                        {{ `${thirdLevelItem.industry_name}-${thirdLevelItem.ts_code_count} | ${thirdLevelItem.up_over10} ${thirdLevelItem.up10_5} ${thirdLevelItem.up5_0}` }}
                      </a-menu-item>
                    </template>
                    <template v-else>
                      <a-menu-item :key="`${subItem.industry_code}-placeholder`" style="display: none"></a-menu-item>
                    </template>
                  </a-sub-menu>
                </template>
                <template v-else>
                  <a-menu-item :key="`${item.industry_code}-placeholder`" style="display: none"></a-menu-item>
                </template>
              </a-sub-menu>
            </a-sub-menu>

            <a-sub-menu key="sw">
              <template v-slot:title>
                <span>申万行业</span>
              </template>
              <a-sub-menu v-for="item in getIndustriesByTypeLevel('S', 1)" :key="item.industry_code" :title="`${item.industry_name} - ${item.ts_code_count} | ${item.up_over10} ${item.up_yearline}`" @click="fetchSubIndustries(item)" @titleClick="handleTitleClick({ key: item.industry_code }, item)">
                <template v-if="subIndustries[item.industry_code]">
                  <a-sub-menu v-for="subItem in subIndustries[item.industry_code]" :key="subItem.industry_code" :title="`${subItem.industry_name} - ${subItem.ts_code_count} | ${subItem.up_over10} ${subItem.up_yearline}`" @click="fetchSubIndustries(subItem)" @titleClick="handleTitleClick({ key: subItem.industry_code }, subItem)">
                    <template v-if="subIndustries[subItem.industry_code]">
                      <a-menu-item v-for="thirdLevelItem in subIndustries[subItem.industry_code]" :key="thirdLevelItem.industry_code" @click="selectIndustry(thirdLevelItem)">
                        {{ `${thirdLevelItem.industry_name} - ${thirdLevelItem.ts_code_count} | ${thirdLevelItem.up_over10} ${thirdLevelItem.up_yearline}` }}
                      </a-menu-item>
                    </template>
                    <template v-else>
                      <a-menu-item :key="`${subItem.industry_code}-placeholder`" style="display: none"></a-menu-item>
                    </template>
                  </a-sub-menu>
                </template>
                <template v-else>
                  <a-menu-item :key="`${item.industry_code}-placeholder`" style="display: none"></a-menu-item>
                </template>
              </a-sub-menu>
            </a-sub-menu>
          </a-menu>
          <p v-else>Loading industries data...</p>
        </a-layout-sider>
        <a-layout-content :style="{marginLeft: sidebarWidth, marginTop: '50px'}">
          <!-- 二级菜单展开内容 -->
          <template v-if="!showInnerInfo && selectedIndustry">
            <div style="background-color: #fff">
              <!-- 全屏按钮 -->
              <a-button class="full-screen" @click="toggleFullScreen">全屏</a-button>
              <!-- 选中行业名称和代码 -->
              <h2 style="font-size: 14px">{{ selectedIndustry.industry_name }} : {{ selectedIndustry.industry_code }}</h2>
              <div style="display: flex; flex-wrap: wrap">
                <!-- K线图容器 -->
                <div id="kline-char-container" style="width: 100%; height: 430px"></div>
                <!-- 当前子行业的迭代显示 -->
                <div v-for="(stock, index) in currentSubIndustry" :key="index" style="width:50%">
                  <h2 style="font-size: 14px">{{ stock.industry_name }} : {{ stock.industry_code }}</h2>
                  <!-- 子行业的K线图容器 -->
                  <div :id="`kline-chart-${index}`" style="height: 430px"></div>
                </div>
              </div>
            </div>
          </template>

          <!-- 三级菜单展开内容 -->
          <template v-if="showInnerInfo">
            <!-- 全屏按钮 -->
            <a-button class="full-screen" @click="toggleFullScreen">全屏</a-button>
            <!-- 弹出模态框 -->
            <a-modal ref="modal" v-model:visible="visible" :title="modalTitle" width="400" :style="{ top: '0px' }">
              <!-- K线图容器 -->
              <div id="kline-char-container" style="width: 100%; height: 600px"></div>
            </a-modal>
            <div v-if="selectedIndustry">
              <!-- 选中行业的名称和代码 -->
              <h2 style="font-size: 16px; color: rgb(21, 21, 162)">{{ selectedIndustry.industry_name }} : {{ selectedIndustry.industry_code }}</h2>
              <!-- K线图按钮 -->
              <a-button type="primary" v-if="showInnerInfo" @click="showKLineChart">K线图</a-button>
              <!-- K线图的弹出模态框 -->
              <a-modal 
                ref="klineModal" 
                v-model:visible="klineVisible" 
                width="100%" 
                height="100%"
                footer="null"
                :bodyStyle="{padding: '0 24px'}"
                style="top: 0; max-width: 100%;"
              >
                <template #title>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div>
                    <span>{{ selectedIndustry.industry_name }}</span>
                    <!-- 按钮 -->
                    <a-button type="default" @click="showAllRanking(selectedIndustry)" style="margin-left: 20px;">机构持仓</a-button>
                    <span style="margin-left: 10px;"><a-button @click="sortAndShowKLineChart('tl_5')">TL_5排序</a-button></span>
                    <span style="margin-left: 10px;"><a-button @click="sortAndShowKLineChart('tl_10')">TL_10排序</a-button></span>
                    <span style="margin-left: 10px;"><a-button @click="sortAndShowKLineChart('tl_20')">TL_20排序</a-button></span>
                  </div>
                  </div>
                </template>
                <div class="kline-modal-content">
                  <div class="row">
                    <!-- 迭代显示股票信息和K线图 -->
                    <template v-for="(stock, index) in tsCodeList" :key="index">
                      <!-- 股票信息 -->
                      <div class="stock-base" :id="`stock-info-${index}`">
                        <!-- 添加 Tooltip 组件 -->
                        <div :style="{ width: '15px', float: 'right', marginRight: '80px' }">
                            <a-tooltip placement="leftTop" :overlayStyle="{ maxWidth: '800px', backgroundColor: '#000', opacity: 1 }">
                                <template #title>
                                    <!-- 使用v-html指令处理换行 -->
                                    <div v-html="formattedAnalysis(stock.ai_main_business)"></div>
                                    <span>{{ stock.introduction }}</span> <span class="separator"></span>
                                </template>
                                <a-button style="border-radius: 8px;">AI主营</a-button>
                            </a-tooltip>
                        </div>

                        <div :style="{ width: `15px`, float: 'right',marginRight: '55px' }">
                            <a-tooltip placement="leftTop" :overlayStyle="{ maxWidth: '1500px', maxHeight: '680px',backgroundColor: '#000', opacity: 1,overflowY: 'auto' }">
                              <template #title>
                                <!-- <span>{{ stock.introduction }}</span> <span class="separator"></span> -->
                                <table v-if="this.stockFund.length">
                                  <thead>
                                    <tr>
                                      <th v-for="(value, index) in Object.keys(stockFund[0])" :key = "index" style="text-align: center;">
                                        {{ value }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <!-- 对每个对象进行遍历 -->
                                    <tr v-for="(item, index) in stockFund" :key="`row-${index}`" >
                                      <!-- 对当前对象的每个值进行遍历 -->
                                      <td v-for="(value, key) in item" :key="key" style="text-align: center;">
                                        {{ value }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </template>
                              <a-button 
                                @mouseover="handleMouseOver(stock.ts_code.substring(0, 6))" 
                                @mouseleave="handleMouseLeave" 
                                style="border-radius: 8px;"
                              >持仓</a-button>
                            </a-tooltip>
                        </div>
                        <p>
                          <span>{{ stock.market }}</span><span class="separator"></span>
                          <span :style="{ color: getColor(stock.r_pct) }">{{ stock.name }}</span><span class="separator"></span>
                          <span :style="{ color: getColor(stock.r_pct) }">{{ stock.ts_code }}</span><span class="separator"></span> 
                          <span :style="{ color: getColor(stock.r_pct) }">{{ stock.r_pct }}%</span> <span class="separator"></span>
                          <span :style="{ color: getColor(stock.r_pct) }">{{ stock.r_close}}</span> <span class="separator"></span>
                          总市值 : <span>{{ stock.total_cap }}亿</span> <span class="separator"></span>
                          流通市值 : <span>{{ stock.free_cap }}亿</span> <span class="separator"></span>
                          流通比 :<span>{{ stock.free_pct }}%</span> <span class="separator"></span>
                          总股本：<span>{{ stock.total_shares }}亿</span> <span class="separator"></span>
                          流通股：<span>{{ stock.float_shares }}亿</span> <span class="separator"></span>
                          自由流通股：<span>{{ stock.free_shares }}亿</span> <span class="separator"></span>
                          限售股：<span>{{ stock.limit_free_shares }}亿</span> <span class="separator"></span>
                          PE : <span>{{ stock.pe }}</span> <span class="separator"></span>
                          员工 : <span>{{ stock.employees }}人</span> 
                        </p>
                        <p>
                          主营 : <span>{{ stock.main_business }}</span> <span class="separator"></span>
                        </p>
                        <p>
                          概念 : <span>{{ stock.ts_concept }}</span> <span class="separator"></span>
                        </p>
                        
                        <!--时间段内的最低涨幅，最高点跌幅 -->
                        <table>
                            <!-- 表头 -->
                            <thead>
                                <tr>
                                    <th style="text-align: center;">昨日</th>
                                    <th style="text-align: center;">5日</th>
                                    <th style="text-align: center;">10日</th>
                                    <th style="text-align: center;">1月</th>
                                    <th style="text-align: center;">2月</th>
                                    <th style="text-align: center;">3月</th>
                                    <th style="text-align: center;">半年</th>
                                    <th style="text-align: center;">1年</th>
                                </tr>
                            </thead>
                            <!-- 表数据 -->
                            <tbody>
                                <tr>
                                    <td :style="{ color: getColor(stock.y_pct) }">{{ stock.y_pct }}%</td>
                                    <td :style="{ color: getColor(stock.tl_5) }">{{ stock.tl_5 }}% | <span :style="{ color: getColor(stock.th_5) }">{{ stock.th_5 }}%</span></td>
                                    <td :style="{ color: getColor(stock.tl_10) }">{{ stock.tl_10 }}% | <span :style="{ color: getColor(stock.th_10) }">{{ stock.th_10 }}%</span></td>
                                    <td :style="{ color: getColor(stock.tl_20) }">{{ stock.tl_20 }}% | <span :style="{ color: getColor(stock.th_20) }">{{ stock.th_20 }}%</span></td>
                                    <td :style="{ color: getColor(stock.tl_40) }">{{ stock.tl_40 }}% | <span :style="{ color: getColor(stock.th_40) }">{{ stock.th_40 }}%</span></td>
                                    <td :style="{ color: getColor(stock.tl_60) }">{{ stock.tl_60 }}% | <span :style="{ color: getColor(stock.th_60) }">{{ stock.th_60 }}%</span></td>
                                    <td :style="{ color: getColor(stock.tl_120) }">{{ stock.tl_120 }}% | <span :style="{ color: getColor(stock.th_120) }">{{ stock.th_120 }}%</span></td>
                                    <td :style="{ color: getColor(stock.tl_240) }">{{ stock.tl_240 }}% | <span :style="{ color: getColor(stock.th_240) }">{{ stock.th_240 }}%</span></td>
                                </tr>
                            </tbody>
                        </table>
                      </div>
                      
                      <!-- 股票的K线图容器 -->
                      <div class="stock_kline_daily">
                        <div :id="`dStockChart-${index}`" style="width: 100%; margin-top: 0px; height: 600px;"></div>
                        <div :id="`bxFund-chart-${index}`" style="width: 100%; margin-top: 0px; height: 200px;"></div>
                      </div>

                      <!-- 财务主营图容器 -->
                      <div class="stock-financial" >
                          <!-- 财务数据标题和容器 -->
                          <div :id="`wStockChart-${index}`" class="chart-container"></div>
                          <div :id="`financial-chart-${index}`" class="chart-container"></div>
                          <div :id="`quarterFund-chart-${index}`"  class="fund-container"></div>
                          <div :id="`yearFund-chart-${index}`"  class="fund-container"></div>

                      </div>
                      <!-- <div class="fund-quare"> -->
                      <!-- <div class="stock_kline_daily">
                      <div :id="`bxFund-chart-${index}`" style="width: 100%; margin-top: 0px; height: 200px;"></div>
                      </div> -->
                      <div class="line"></div>

                    </template>
                  </div>
                </div>
              </a-modal>
              <!-- 表格显示行业股票数据 -->
              <div style="text-align: right; margin-right: 10px">
                <a-table 
                  v-if="indusStockData && indusStockData.length && showInnerInfo" 
                  :columns="columns" 
                  :dataSource="indusStockData" 
                  :defaultSortOrder="'ascend'" 
                  :scroll="{ x: 'max-content', y: 'calc(100vh - 250px)' }"
                  :pagination="{ defaultPageSize: 300 }"
                  :customRow="onTableRow"
                  :row-class-name="'row-height'"
                  :sticky="true"
                >
                </a-table>
              </div>
            </div>
          </template>

          <!-- 页面内容 -->
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
  import { getIndustry, getStockBusiness, getStockRankUpYearLine, getStockFinance, getStockFund, getStockFundQy} from "@/api/stock_api";
  import { renderKlineChart } from "@/js/klineChart";
  import { dailyKlineChart } from "@/js/getDailyStockLine";
  import { weekKlineChart } from "@/js/getWeekStockLine";
  import { renderHistogram } from "@/js/histogram";
  import { renderBarLabel } from "@/js/barLabelChart";
  import { renderDualAxisLine } from "@/js/dualAxisLine";
  import StockNavbar from "@/components/StockNavbar.vue";
  import { notification } from "ant-design-vue";


  export default {
    data() {
      return {
        // industries: 行业数据数组
        industries: [],
        // industriesLoaded: 是否已加载行业数据的标志
        industriesLoaded: false,
        // subIndustries: 子行业的映射对象
        subIndustries: {},
        // selectedIndustry: 当前选中的行业
        selectedIndustry: null,
        // selectedRowData: 当前选中行的数据
        selectedRowData: null,
        // indusStockData: 行业股票数据数组
        indusStockData: [],
        // columns: 表格列的定义
        columns: [],
        // visible: 控制模态框显示状态的属性
        visible: false,
        // klineVisible: 控制K线图模态框显示状态的属性
        klineVisible: false,
        // tsCodeList: 股票代码列表
        tsCodeList: [],
        // stockBasicInfo: 股票基本信息对象
        stockBasicInfo: {},
        // openKeys: 当前展开的SubMenu菜单项key数组
        openKeys: [],
        // showInnerInfo: 是否显示内部信息的标志
        showInnerInfo: false,
        // currentSubIndustry: 当前子行业数据数组
        currentSubIndustry: [],
        // financialList: 财务数据列表
        financialList: [],
        // financialData: 财务数据数组
        financialData: [],
        // isSidebarCollapsed: 侧边栏是否折叠的标志
        isSidebarCollapsed: false,
        // sidebarWidth: 侧边栏的宽度，初始为展开状态
        sidebarWidth: '260px',
        stockFund: [],
        hasRequestedDuringHover: false,
      };
    },
    components: {
      // StockNavbar: 股票导航组件
      StockNavbar,
    },
    computed: {
      // modalTitle: 计算属性，用于获取模态框标题
      modalTitle() {
        if (this.selectedRowData) {
          return `${this.selectedRowData.name} (${this.selectedRowData.ts_code})`;
        } else {
          return "Selected Row Data";
        }
      },
    },
    mounted() {
      // 获取行业数据
      getIndustry()
        .then((response) => {
          this.industries = response;
          this.industriesLoaded = true;
        })
        .catch((error) => {
          // 错误处理：打印错误信息到控制台
          console.error("Error fetching industries:", error);
        });
    },
    // 其他方法和生命周期钩子...
    methods: {
      formattedAnalysis(analysisText) {
        // Parse JSON string to get analysis data
        let data = JSON.parse(analysisText);

        let formattedText = '';
        for (const key in data) {
          // Check if the key is actually a property of the data object
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            // Add key and replace newlines in value with HTML line breaks
            formattedText += `<strong>${key}:</strong><br>`;
            formattedText += `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${data[key].replace(/\n/g, '<br>&nbsp;&nbsp;&nbsp;&nbsp;')}<br>`;
          }
        }

        return formattedText;
      },
      getIndustriesByTypeLevel(indus_type, level) {
        return this.industries.filter((item) => item.indus_type === indus_type && item.level === level);
      },
      fetchSubIndustries(industry) {
        if (!this.subIndustries[industry.industry_code]) {
          const subIndustries = this.industries.filter((item) => item.parent_code === industry.industry_code);
          if (subIndustries.length) {
            // 使用 Vue.set 方法添加属性
            Reflect.set(this.subIndustries, industry.industry_code, subIndustries);
          } else {
            // 如果没有子行业，可以在这里处理，例如显示提示信息等
          }
        }
      },
      generateColumns(data, columnOrder) {
        return columnOrder.map((key) => ({
          title: key,
          dataIndex: key,
          key: key,
          width: 120,
          sorter: (a, b) => {
            if (typeof a[key] === "number" && typeof b[key] === "number") {
              return a[key] - b[key];
            } else if (typeof a[key] === "string" && typeof b[key] === "string") {
              return a[key].localeCompare(b[key]);
            } else {
              return 0;
            }
          },
          // fixed: key === "market" ? "left" : 0,
        }));
      },
      getStockRankUpYearLine(indus_type, indus_code, indus_level) {
          getStockRankUpYearLine(indus_type, indus_code, indus_level)
              .then((response) => {
                  if (response && Array.isArray(response)) {
                      this.indusStockData = response.map((stockObject) => ({
                          ...stockObject,
                      }));
                      this.columns = this.generateColumns(response[0], ["market","fund_cap","name","r_pct", "y_pct","tl_5", "tl_20", "tl_40", "tl_120", "tl_240","th_3","th_5", "th_60", "th_120", "th_240"]);
                  } else {
                      console.error("Unexpected response format:", response);
                  }
              })
              .catch((error) => {
                  console.error("Error fetching stock data:", error);
              });
      },
      selectIndustry(industry) {
          // console.log(industry);  // 打印整个industry对象
          this.showInnerInfo = true;
          this.selectedIndustry = {
              industry_name: industry.industry_name,
              industry_code: industry.industry_code,
              indus_type: industry.indus_type,
              indus_level: industry.level,
          };
          // console.log(industry.indus_type, industry.industry_code, industry.level);  // 打印特定的属性值
          this.getStockRankUpYearLine(industry.indus_type, industry.industry_code, industry.level);
      },

      applySort(index) {
        // 根据 index 执行相应的排序操作
        console.log("应用排序", index + 1);
      },
      onTableRow(record) {
        return {
          onClick: () => {
            this.handleRowClick(record);
          },
        };
      },
      onModalOpen() {
        // console.log("onModalOpen called");
        this.$nextTick(() => {
          const containerElement = document.getElementById("kline-char-container");
          if (containerElement && this.selectedRowData.ts_code) {
            renderKlineChart("kline-char-container", {
              k_type: "S",
              ts_code: this.selectedRowData.ts_code,
            });
            //  containerElement.style.position = "absolute";
            containerElement.style.left = "0";
            containerElement.style.top = "0";
          } else {
            console.error("Error: Either kline-char-container is not present in the DOM or ts_code is missing");
          }
        });
      },

      handleRowClick(record) {
        this.selectedRowData = record;
        this.visible = true;
      },

      sortAndShowKLineChart(sortKey) {
        this.tsCodeList.sort((a, b) => a[sortKey] - b[sortKey]);
        this.$nextTick(() => {
          // 强制刷新组件以更新DOM
          this.$forceUpdate();
          // 现在您可以重新调用任何渲染图表的函数
          this.showKLineChart();
        });
      },


      async showKLineChart() {
          this.klineVisible = true;
          this.tsCodeList = this.indusStockData.map((stock) => {
            return {
              market: stock.market,
              total_shares: stock.total_shares,
              float_shares: stock.float_shares,
              free_shares: stock.free_shares, 
              limit_free_shares: stock.limit_free_shares,
              total_cap: stock.total_cap,
              free_cap: stock.free_cap,
              free_pct: stock.free_pct,
              pe: stock.pe,
              PE_TTM: stock.PE_TTM,
              employees: stock.employees,
              y_close: stock.y_close,
              name: stock.name,
              ts_code: stock.ts_code,
              r_pct: stock.r_pct,
              r: stock.r_close,
              y_pct: stock.y_pct,
              tl_5:stock.tl_5,
              tl_10:stock.tl_10,
              tl_20:stock.tl_20,
              tl_40:stock.tl_40,
              tl_60:stock.tl_60,
              tl_120:stock.tl_120,
              tl_240:stock.tl_240,
              th_5:stock.th_5,
              th_10:stock.th_10,
              th_20:stock.th_20,
              th_40:stock.th_40,
              th_60:stock.th_60,
              th_120:stock.th_120,
              th_240:stock.th_240,
              introduction: stock.introduction,
              main_business: stock.main_business,
              ai_main_business:stock.ai_main_business,
              ts_concept: stock.ts_concept,
            };
          });

          // 使用 $nextTick 确保 DOM 更新完成
          await this.$nextTick();

          // 使用 for...of 循环来一个接一个地处理每个股票
          for (let index = 0; index < this.tsCodeList.length; index++) {
              const stock = this.tsCodeList[index];

              const businessResponse = await getStockBusiness({ ts_code: stock.ts_code });
              const financeResponse = await getStockFinance({ ts_code: stock.ts_code });
              const yearFundResponse = await getStockFundQy({ ts_code: stock.ts_code, report_period: 'y',org_type: 'n' });
              const quarterFundResponse = await getStockFundQy({ ts_code: stock.ts_code, report_period: 'q',org_type: 'n' });
              const dayFundResponse = await getStockFundQy({ ts_code: stock.ts_code, report_period: 'q', org_type: 'b' });


              
              // dailyKlineChart(`fStockChart-${index}`, { k_type: "f", ts_code: stock.ts_code });
              dailyKlineChart(`dStockChart-${index}`, { k_type: "d", ts_code: stock.ts_code });
              weekKlineChart(`wStockChart-${index}`, { k_type: "w", ts_code: stock.ts_code });
              // weekKlineChart(`mStockChart-${index}`, { k_type: "m", ts_code: stock.ts_code });

              const chartDom = document.getElementById(`main-chart-${index}`);
              if (chartDom) {
                  renderHistogram(chartDom, businessResponse);
              }

              const financialDom = document.getElementById(`financial-chart-${index}`);
              if (financialDom) {
                  renderBarLabel(financialDom, financeResponse);
              }

              const yearFundDom = document.getElementById(`yearFund-chart-${index}`);
              if (yearFundDom) {
                renderDualAxisLine(yearFundDom, yearFundResponse);
              }

              const quarterFundDom = document.getElementById(`quarterFund-chart-${index}`);
              if (quarterFundDom) {
                renderDualAxisLine(quarterFundDom, quarterFundResponse);
              }

              const dayFundDom = document.getElementById(`bxFund-chart-${index}`);
              if (dayFundDom) {
                renderDualAxisLine(dayFundDom, dayFundResponse);
              }
          }
      },
      getColor(value) {
        return value > 0 ? 'red' : value < 0 ? 'green' : 'black';
      },
      handleTitleClick({ key }, industry) {
        const subIndustries = this.industries.filter((item) => item.parent_code === industry.industry_code);
        this.currentSubIndustry = subIndustries;
        this.openKeys = [key];
        this.selectedIndustry = industry;
        this.displayIndustry(industry);
        this.showKLine(industry.industry_code, this.currentSubIndustry);
      },
      displayIndustry(industry) {
        this.selectedIndustry = industry;
        this.showInnerInfo = false;
      },
      showKLine(industryCode, subIndustryCodes) {
        this.$nextTick(() => {
          const containerElement = document.getElementById("kline-char-container");
          if (containerElement && industryCode) {
            renderKlineChart("kline-char-container", {
              k_type: "I",
              ts_code: industryCode,
            });
            //  containerElement.style.position = "absolute";
            containerElement.style.left = "0";
            containerElement.style.top = "0";
          } else {
            console.error("Error: Either kline-char-container is not present in the DOM or ts_code is missing");
          }
        });
        this.$nextTick(() => {
          subIndustryCodes.forEach((stock, index) => {
            renderKlineChart(`kline-chart-${index}`, {
              k_type: "I",
              ts_code: stock.industry_code,
            });
          });
        });
      },
      toggleFullScreen() {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
      },
      showIntroduction(stock) {
        notification.open({
          message: stock.name,
          description: stock.introduction,
          placement: "topLeft",
          duration: 15,
          onClick: () => {
            // console.log("Notification Clicked!");
          },
        });
      },
      handleSidebarCollapse(collapsed) {
      this.isSidebarCollapsed = collapsed;
      // 根据展开和收缩状态设置展开内容的宽度
      this.sidebarWidth = collapsed ? '90px' : '260px';
    },
    handleMouseOver(data) {
      if (!this.hasRequestedDuringHover) {
        this.renderGetStockFund(data);
        this.hasRequestedDuringHover = true;
      }
    },
    handleMouseLeave() {
      this.hasRequestedDuringHover = false;
    },
    renderGetStockFund(data) {
      getStockFund(data)
        .then((response) => {
          this.stockFund = response;
        })
        .catch((error) => {
          // 错误处理：打印错误信息到控制台
          console.error(error);
        })
    },
    async showAllRanking(param) {
      try {
// 序列化对象为JSON字符串
const serializedParam = encodeURIComponent(JSON.stringify(param));
    const baseUrl = window.location.origin;
    const path = '/RankingPage'; // 确保这里的路径与你的路由配置匹配
    // 将序列化后的字符串作为查询参数
    const queryString = `?param=${serializedParam}`;
    window.open(`${baseUrl}${path}${queryString}`, '_blank');
  } catch (error) {
    console.error('Error preparing for ranking page:', error);
  }
    },
    },
    watch: {
      visible(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          this.onModalOpen();
        }
      },
    },
  };
</script>

<style scoped>
.custom-tooltip-content span {
  color: #000; /* Black color for the text */
}

  .kline-modal-content {
    max-height: 100vh; /* 设置最大高度为80%视口高度 */
    overflow-y: auto; /* 垂直方向溢出方式为自动滚动 */
    padding: 0 24px;
  }
  .row-height {
    height: 50px !important;
  }

  .fixed-row {
    position: sticky;
    top: 0;
    background-color: white; /* 可选：添加背景色以覆盖默认样式 */
  }

  .ts_sw_stock {
    height: 100%;
  }

  ::v-deep .ant-modal {
    top: 0;
    max-width: 100%;
  }

  .ant-modal-header {
    padding: 8px 24px;
  }

  .ant-modal-close-x {
    height: 40px;
    line-height: 40px;
  }

  .ant-layout-sider-has-trigger {
    padding-bottom: 0px;
  }

  .ts_sw_stock .ant-table {
    overflow-x: auto;
    width: 100%;
  }
  .ts_sw_stock .ant-table-row,
  .ts_sw_stock .ant-table-row:hover {
    height: 20px !important;
    line-height: 20px !important;
  }

  .ts_sw_stock .ant-table-row > td {
    padding: 4px 16px !important;
  }
  .ant-dropdown-link {
    color: black;
    text-decoration: none;
    display: inline-block;
    padding: 0 16px;
    font-size: 16px;
    font-weight: bold;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .stock-base {
  font-weight: 400;
  width:100%;
  }
  .stock-base span {
    margin-right: 1px;  /* 为<span>元素的右侧增加10px的外部间距 */
  }

  .stock-detail-info {
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
  }

  .separator {
    margin: 0 5px;  /* 在"-"和"|"左右两侧都增加5px的外部间距 */
  }
  .stock_kline_daily {
    position: relative;
    padding-right: 0px;
    padding-left: 0px;
    flex: 0 0 60%;
  }
  .stock-financial {
    position: relative;
    /* min-height: 500px;  */
    padding-right: 0px;
    padding-left: 0px;
    flex: 0 0 39%;
  }

  .stock-info {
    position: absolute;
    top: 0;
    left: 10px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
    width: 100%;
  }

  .indent-text {
    text-indent: 2em; /* 缩进长度，您可以根据需要调整这个值 */
  }

  .full-screen {
    position: absolute;
    right: 0;
    top: 50px;
  }

  /* 全屏状态下 */
  :-webkit-full-screen {
    width: 100%;
    height: 100%;
  }
  :-moz-full-screen {
    width: 100%;
    height: 100%;
  }
  :full-screen {
    width: 100%;
    height: 100%;
  }

  /* 非全屏状态下 */
  :-webkit-full-screen {
    width: auto;
    height: auto;
  }
  :-moz-full-screen {
    width: auto;
    height: auto;
  }
  :full-screen {
    width: auto;
    height: auto;
  }

.line {
  height: 1px;
  width: 100%;
  margin: 30px 0;
  background: #c2c2c2;
}

.stock-info {
  margin-bottom: 1px;
}

.financial-title {
  font-weight: 600;
  font-size: 1em;
  margin: 20px 0;
}

.chart-container {
  width: 100%;
  height: 200px;
  margin: 10px 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.fund-container {
  width: 100%;
  height: 200px;
  margin: 10px 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.stock-week-month {  
  position: relative;
    width: 100%;
    display: flex;          /* 添加flexbox布局 */
    justify-content: space-between; 
}  
  
.week-month-data {  
  display: flex;          /* 添加flexbox布局 */
  width: 100%;
}  
  
.wmchart-container {  
  width: calc(50% - 28px); /* 让每个容器的宽度为父容器的50%减去10px的间距 */
  height: 200px;
  margin: 10px 14px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);  
}


td {
  padding-left: 15px;
  padding-right: 15px;
}

.fund-quare {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
                       
</style>

@/js/getDailyStockLine