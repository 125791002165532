/*
 * @Author: lianan27 lianan_27@163.com
 * @Date: 2023-10-27 22:44:55
 * @LastEditors: lianan27 lianan_27@163.com
 * @LastEditTime: 2023-10-28 23:54:41
 * @FilePath: \stockvue3\src\js\histogram.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import * as echarts from 'echarts'

export function renderHistogram(chartDom, list) {
    var myChart = echarts.init(chartDom)
    var option

    option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        // legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top:'5%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
            type: 'value',
        },
        series: [],
        // 添加时间轴
        // dataZoom: [
        //     {
        //         type: 'slider',
        //         xAxisIndex: 0,
        //         filterMode: 'empty',
        //         bottom: 5,
        //         height: 10,
        //     },
        // ],
    }
    // 获取 unique_end_dates 数组
    let newList = [...list]
    const uniqueEndDates =
        newList.find((item) => item.unique_end_dates !== undefined)
            ?.unique_end_dates || []

    // 设置 xAxis 的 data
    option.xAxis.data = uniqueEndDates
    // 创建一个 Map 用于记录已添加过的数据
    const addedDataMap = new Map()
    // 遍历每个 bz_item，处理对应的数据
    newList.forEach((item) => {
        if (item.unique_end_dates === undefined) {
            // 如果该 bz_item 数据已经添加过，则跳过
            if (addedDataMap.has(item.bz_item)) {
                return
            }
            // 创建 series 数据对象
            const seriesData = {
                name: item.bz_item,
                type: 'bar',
                stack: 'total',
                label: {
                    show: false,
                },
                emphasis: {
                    focus: 'series',
                },
                data: [],
            }

            // 遍历 unique_end_dates，获取对应的 bz_sales
            uniqueEndDates.forEach((date) => {
                const bzSales = newList.find(
                    (innerItem) =>
                        innerItem.bz_item === item.bz_item &&
                        innerItem.end_date === date
                )?.bz_sales
                seriesData.data.push(bzSales)
            })

            // 添加到 series 中
            option.series.push(seriesData)

            // 将该 bz_item 标记为已添加
            addedDataMap.set(item.bz_item, true)
        }
    })
    option && myChart.setOption(option)
}
