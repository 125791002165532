import * as echarts from 'echarts';

// 假设这个函数接收一个 DOM 元素和数据数组
export function renderDualAxisLine(chartDom, data) {
  // 基于准备好的dom，初始化echarts实例
  // var myChart = echarts.init(chartDom);
  var myChart = echarts.init(chartDom, null, {
    renderer: 'canvas', // 或者 'svg'，取决于你的需求
    useDirtyRect: false // false 为默认值，设置为 true 可以提高性能，但可能在某些情况下会导致渲染问题
  });
  
  // 确保在窗口大小变化时，图表也随之变化
  window.onresize = function() {
    myChart.resize();
  };
  

  // 配置选项
  var option = {
    color: ['#5470C6', '#91CC75', '#EE6666'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },
    grid: [
      {
        left: '0%',
        right: '5%',
        bottom: '0%',
        top: '15%',
        containLabel: true
      },
      {
        left: '3%',
        right: '3%',
        top: '0%',
        bottom: '0%',
      },
    ],
    // grid: {
    //   right: '20%'
    // },
    toolbox: {
      toolbox: {
        show: false, // 或者删掉整个 toolbox 配置块，效果相同
      },
    },
    // legend: {
    //   data: ['Shares', 'Cap', 'Rate']
    // },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        data: data.map(item => item.report_period)
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Shares',
        position: 'left',
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#5470C6'
          }
        },
        axisLabel: {
          formatter: '{value}'
        }
      },
      {
        type: 'value',
        name: 'Cap',
        position: 'right',
        alignTicks: true,
        // offset: 80,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#91CC75'
          }
        },
        axisLabel: {
          formatter: '{value}'
        }
      },
      {
        type: 'value',
        name: 'FRate',
        position: 'right',
        alignTicks: true,
        offset: 40,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#ed6666'
          }
        },
        axisLabel: {
          formatter: function (value) {
            return value.toFixed(0);
          }
        }
      }
    ],
    series: [
      {
        name: 'Shares',
        type: 'bar',
        data: data.map(item => item.shares)
      },
      {
        name: 'Cap',
        type: 'bar',
        yAxisIndex: 1,
        data: data.map(item => parseFloat(item.hold_cap))
      },
      {
        name: 'Rate',
        type: 'line',
        smooth: true,
        symbol: 'none',
        yAxisIndex: 2,
        data: data.map(item => parseFloat(item.hold_float_rate))
      }
    ]
  };

  // 使用刚指定的配置项和数据显示图表。
  myChart.setOption(option);
}
