import * as echarts from 'echarts';

const filterDataByReportType = (reportType, rawData) => {
  const targetMonth = reportType === 'annual' ? '12' : '06';
  return rawData.filter(item => item[1].slice(4,6) === targetMonth);
};

export const createStockBusinessChart = (containerId, rawData, reportType) => {
  const filteredData = filterDataByReportType(reportType, rawData);
  const processedData = filteredData.map(item => {
    return {
      code: item[0],
      date: item[1],
      business: item[2],
      sales: Math.round(item[3] / 100000000)
    };
  });

  const chartContainer = document.getElementById(containerId);
  const chart = echarts.init(chartContainer);

  const dates = Array.from(new Set(processedData.map(item => item.date)));

  const businesses = [];
  rawData.forEach(item => {
    const business = item[2];
    if (!businesses.includes(business)) {
      businesses.push(business);
    }
  });

  const sourceData = dates.map(date => {
    const rowData = {};
    rowData['日期'] = date;
    processedData
      .filter(item => item.date === date)
      .forEach(item => {
        rowData[item.business] = item.sales;
      });
    return rowData;
  });

  const option = {
    legend: {show : false},
    tooltip: {},
    dataset: {
      dimensions: ['日期', ...businesses],
      source: sourceData,
    },
    xAxis: {
        type: 'category',
        axisTick: {
            interval: 0,
        },
        axisLabel: {
            interval: 0,
            rotate: 45,
        },
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            formatter: '{value}亿'
        }
    },
    dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100
        }
    ],
    series: businesses.map(business => ({
      name: business,
      type: 'bar',
      stack: 'sales',
      encode: {
        x: '日期',
        y: business,
      },
    })),
  };

  chart.setOption(option);
};
