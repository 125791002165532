<template>
    <div>
      <StockNavbar />
      <div>这里是 Index 页面的内容</div>
    </div>
  </template>
  
  <script>
  import StockNavbar from '@/components/StockNavbar.vue';
  
  export default {
    name: 'IndexPage', // 将组件名修改为多个单词组成的形式
    components: {
      StockNavbar,
    },
  };
  </script>
  