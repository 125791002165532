/*
 * @Author: lianan27 lianan_27@163.com
 * @Date: 2023-10-30 21:19:48
 * @LastEditors: lianan27 lianan_27@163.com
 * @LastEditTime: 2024-03-10 00:07:00
 * @FilePath: \stockvue3\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory } from 'vue-router'
import TsSwStock from '@/pages/ts_sw_stock.vue'
import StockKline from '@/pages/stock_kline.vue'
import IndexPage from '@/pages/IndexPage.vue'
import HotAnalyze from '@/pages/HotAnalyze.vue'
import StockRank from '@/pages/StockRank.vue'
import MyStock from '@/pages/MyStock.vue'
import SerachStock from '@/pages/SerachStock.vue'
import RankingPage from '@/pages/RankingPage.vue'

const routes = [
    {
        path: '/',
        name: 'Index',
        component: IndexPage
    },
    {
        path: '/HotAnalyze',
        name: 'HotAnalyze',
        component: HotAnalyze,
        props: true,
    },
    {
        path: '/StockRank',
        name: 'StockRank',
        component: StockRank
    },
    {
        path: '/MyStock',
        name: 'MyStock',
        component: MyStock
    },
    {
        path: '/SerachStock',
        name: 'SerachStock',
        component: SerachStock
    },

    {
        path: '/ts_sw_stock',
        name: 'TsSwStock',
        component: TsSwStock
    },
    {
        path: '/stock_kline',
        name: 'StockKline',
        component: StockKline
    },
    {
        path: '/RankingPage',
        name: 'RankingPage',
        component: RankingPage,
        props: true, // 允许组件通过props接收到路由参数
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
