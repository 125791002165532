<template>
  <div>
    <div id="stock-business-chart" style="width: 100%; height: 400px;"></div>
    <div>
      <input type="radio" id="annual" name="reportType" value="annual" checked>
      <label for="annual">年报</label>
      <input type="radio" id="semiannual" name="reportType" value="semiannual">
      <label for="semiannual">半年报</label>
    </div>
  </div>
</template>

<script>
import { createStockBusinessChart } from '@/js/stockBusinessChart';
import { getStockBusiness } from '@/api/stock_api';

export default {
  name: 'KlineChart',
  data() {
    return {
      rawData: [],
      reportType: 'annual'
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await getStockBusiness({ ts_code: '688599.SH' });
        // console.log('API response:', response); // Log the entire response object
        this.rawData = response.data || response; // 修改此行
        // console.log('rawData:', this.rawData); // Log the entire response object

        createStockBusinessChart('stock-business-chart', this.rawData, this.reportType);
      } catch (error) {
        console.error('Error fetching stock business data:', error);
      }
    },
    onReportTypeChange() {
      createStockBusinessChart('stock-business-chart', this.rawData, this.reportType);
    }
  },
  async mounted() {
    await this.fetchData();
    const annualRadio = document.getElementById('annual');
    const semiannualRadio = document.getElementById('semiannual');
    annualRadio.addEventListener('change', this.onReportTypeChange);
    semiannualRadio.addEventListener('change', this.onReportTypeChange);
  },
};
</script>
